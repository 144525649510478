var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    { attrs: { icon: "home", title: "Home" } },
    [
      _c("emc-chart-general-institution-attribution", {
        attrs: { show: _vm.showMoreAttributions },
        on: {
          "update:show": function($event) {
            _vm.showMoreAttributions = $event
          }
        }
      }),
      _c("emc-chart-general-institution-time-table", {
        attrs: { show: _vm.showMoreTimeTable },
        on: {
          "update:show": function($event) {
            _vm.showMoreTimeTable = $event
          }
        }
      }),
      _c("emc-chart-general-institution-ambience", {
        attrs: { show: _vm.showMoreAmbiences },
        on: {
          "update:show": function($event) {
            _vm.showMoreAmbiences = $event
          }
        }
      }),
      _c("emc-chart-general-institution-balance", {
        attrs: { show: _vm.showMoreBalance },
        on: {
          "update:show": function($event) {
            _vm.showMoreBalance = $event
          }
        }
      }),
      _c("emc-chart-general-institution-work-load-avg", {
        attrs: { show: _vm.showMoreWorkLoadAvg },
        on: {
          "update:show": function($event) {
            _vm.showMoreWorkLoadAvg = $event
          }
        }
      }),
      _c(
        "v-row",
        { staticClass: "mt-2" },
        _vm._l(
          _vm.charts.filter(function(c) {
            return c.type == "P"
          }),
          function(chart, i) {
            return _c(
              "v-col",
              { key: i, attrs: { cols: "12", md: "2" } },
              [_c(chart.component, { tag: "v-component" })],
              1
            )
          }
        ),
        1
      ),
      _c(
        "v-row",
        _vm._l(
          _vm.charts.filter(function(c) {
            return c.type == "M"
          }),
          function(chart, i) {
            return _c(
              "v-col",
              { key: i, attrs: { cols: "12", md: "3" } },
              [
                _c(chart.component, {
                  tag: "v-component",
                  attrs: {
                    "show-more-button": _vm.showMoreButton,
                    "show-actions": _vm.showMoreButton,
                    "hide-tools": _vm.showMoreButton
                  },
                  on: {
                    showMoreAttributions: function($event) {
                      _vm.showMoreAttributions = true
                    },
                    showMoreTimeTable: function($event) {
                      _vm.showMoreTimeTable = true
                    },
                    showMoreAmbiences: function($event) {
                      _vm.showMoreAmbiences = true
                    },
                    showMoreWorkLoadAvg: function($event) {
                      _vm.showMoreWorkLoadAvg = true
                    }
                  }
                })
              ],
              1
            )
          }
        ),
        1
      ),
      _c(
        "v-row",
        _vm._l(
          _vm.charts.filter(function(c) {
            return c.type == "G"
          }),
          function(chart, i) {
            return _c(
              "v-col",
              { key: i, attrs: { cols: "12", md: "4" } },
              [
                _c(chart.component, {
                  tag: "v-component",
                  attrs: { "show-more-button": _vm.showMoreButton },
                  on: {
                    showMore: function($event) {
                      _vm.showMoreBalance = true
                    }
                  }
                })
              ],
              1
            )
          }
        ),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }